import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles
} from "../../components/server-guide";
import {Callout} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {CodeRef} from "../../components/code";
import {ApiLink} from "../../components/link";
import {OutputParamNotes} from "../../components/sql-server/output-parameters";
import {SqlServerUserID} from "../../components/sql-server/sql-server-user-id";
import {options} from "../../data/sql-server-odbc";


const connectApiNotes = ({optionsLink}) => (
    <>
        <Callout heading="Default API">
            Starting from version 4.0.3 SQLAPI++ library uses ODBC as default API when working with
            SQL Server. It means that the library sets the default value of {' '}<code>UseAPI</code>{' '}
            {' '}<a className="alert-link" href={optionsLink}>connection option</a>{' '} as {' '}<code>"ODBC"</code>.
            If the library cannot find ODBC interface it throws an exception.
        </Callout>
        <Callout heading="Minimum Version">
            SQLAPI++ library requires SQLServer ODBC version 2000.x or SQL Server Native Client (ODBC API) version
            2005 and higher.
        </Callout>
    </>
);

const sDBString = () => (
    <>
        <div>Connection string in the following format:</div>
        <CodeSnippet>
            {`[<server_name>@][<database_name>][;<driver_connection_option_list>]`}
        </CodeSnippet>

        <ul>
            <li>
                <CodeRef>{`<server_name>`}</CodeRef>{' '} - connects to a specified server. If it's omitted SQLAPI++
                tries to connect to default local server instance
            </li>
            <li>
                <CodeRef>{`<database_name>`}</CodeRef>{' '} - connects to a database with the specified name. If
                it's omitted SQLAPI++ tries to connect to default database
            </li>
            <li>
                <CodeRef>{`<driver_connection_option_list>`}</CodeRef>{' '} - SQL Server Native ODBC driver specific
                option list
            </li>
        </ul>

        <p>
            To connect to a named instance of SQL Server
            use {' '}<CodeRef>{`<server name\\instance name>`}</CodeRef>{' '} instead
            of {' '}<CodeRef>{`<server_name>`}</CodeRef>{' '}
            (use double back slash in C++ constants, for
            example <CodeRef>{`"MyServer\\\\SQLEXPRESS@pubs"`}</CodeRef>).
        </p>

        <div>
            Since SQLNCLI (SQL Server 2005) also available protocol specific server name part of the connection string:
        </div>
        <ul>
            <li><CodeRef>{`lpc:<servername>[\\instancename]`}</CodeRef> - using shared memory</li>
            <li>
                <CodeRef>{`tcp:<servername>[\\<instancename>],<port>`}</CodeRef> or <CodeRef>{`tcp:<IPAddress>[\\<instancename>],<port>`}</CodeRef> -
                using TCP/IP
            </li>
            <li>
                <CodeRef>{`np:\\<computer_name>\\pipe\\<pipename>`}</CodeRef> or <CodeRef>{`np:\\<IPAddress>\\pipe\\<pipename>`}</CodeRef> -
                using named pipes
            </li>
            <li><CodeRef>{`via:<servername>[\\instancename],<nic_number>:<port>`}</CodeRef> - using VIA</li>
        </ul>
    </>
);

const ErrorNotes = () => (
    <p>
        In SQL Server (ODBC) API {' '}<ApiLink>SAException::ErrPos</ApiLink>{' '} method returns the number of the line
        within SQL statement where the error occurred.
    </p>
);

const Page = () => (
    <ServerGuide server="SQL Server (ODBC)"
                 connectApiNotes={connectApiNotes}
                 sDBString={sDBString}
                 sUserID={SqlServerUserID}
                 outputParamNotes={() => <OutputParamNotes api="ODBC"/>}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="SQL Server (ODBC)"
                                                                      nativeCommand="SQLCancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="SQL Server (ODBC)"
                                                                        cppHeader="ssNcliAPI.h"
                                                                        apiClass="ssNCliAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="SQL Server (ODBC)"
                                                                                handleClass="ssNCliConnectionHandles"
                                                                                cppHeader="ssNcliAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="SQL Server (ODBC)"
                                                                          handleClass="ssNCliCommandHandles"
                                                                          cppHeader="ssNcliAPI.h"/>}
                 errorNotes={ErrorNotes}
    />
);

export default Page;
